@import "../../../styles/_colors.scss";

$stuck-top: 80px;

.sticky-toolbar {
    padding-bottom: 4px;

    &.sticky-toolbar-unstuck {
    }

    &.sticky-toolbar-stuck {
        position: fixed;
        top: $stuck-top;
        left: 0px;
        right: 0px;
        z-index: 1017;
        padding-top: 4px;
        background-color: $white;
    }
    // Give some space between buttons that are not in button groups as this is a very common use case for the toolbar.
    .btn {
        margin-right: 2px;
    }

    .btn-group {
        margin-right: 2px;

        .btn {
            margin-right: 0px;
        }
    }
}
