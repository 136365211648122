@import "../../styles/_colors.scss";
@import "../../styles/_breakpoints.scss";

.profile-card {
    width: 600px;
    padding: 2rem;
    margin: 0 auto;
    border: solid 2px $black;

    &-inner-wrapper {
        display: grid;
        grid-template-columns: 1fr 7fr;

        @include media-breakpoint-down(xs) {
            grid-template-columns: 1fr;
        }
    }

    &-image {
        width: 250px;
        height: 250px;
        border-radius: 50%;
        border: 1px solid $gray-300;
    }

    &-details {
        display: flex;
        flex-direction: column;
        padding: 0 2rem;

        &-info {
            display: flex;
            flex-direction: row;

            a {
                color: $white !important;
            }

            &-item {
                width: 15% auto;
                background: $primary;
                text-align: center;
                padding: 1rem 1rem;

                &:not(:last-child) {
                    border-right: solid 2px $white;
                }

                &:first-child {
                    border-top-left-radius: 20px;
                    border-bottom-left-radius: 20px;
                }

                &:last-child {
                    line-height: 2rem;
                    font-size: 2rem;
                    border-top-right-radius: 20px;
                    border-bottom-right-radius: 20px;
                }

                &-heading {
                    color: $white;
                    font-weight: 700;
                    font-size: 1.25rem;
                }

                &-value {
                    color: $white;
                    font-weight: 600;
                }

                &-linkValue {
                    color: $white;
                    font-weight: 600;
                }

                &-linkValue:hover, &-linkValue:active {
                    color: #3d4ffa;
                    cursor: pointer;
                }
            }
        }
    }
}

.school-or-trust-image {
    width: 100%;
    height: 200px;
    background-position-x: center;
    background-position-y: center;
    background-size: contain;
    background-repeat: no-repeat;
    border-radius: 5px;
}
