@import "../../../styles/_colors.scss";

.pills-nav-bar {
    .nav-item {
        min-height: 100%;
        flex-grow: 1;
        text-align: center;

        a {
            min-height: 100%;
            border-style: solid;
            border-color: $primary;
            border-width: 1px;
            border-left-style: none;
            border-radius: 0px 0px;
            cursor: pointer;
        }

        &:first-child {
            a {
                border-top-left-radius: 1rem;
                border-bottom-left-radius: 1rem;
                border-left-style: solid;
            }
        }

        &:last-child {
            a {
                border-top-right-radius: 1rem;
                border-bottom-right-radius: 1rem;
            }
        }
    }
    // Handle the textColour options
    //
    &.pills-nav-bar-text-color-inherit {
        // Nothing to do here.
    }

    &.pills-nav-bar-text-color-white {
        color: $white;
    }
}

// When we are under a sticky toolbar, center our items.
.sticky-toolbar-stuck {
    .pills-nav-bar {
        justify-content: center;
    }
}