@import "../../styles/_colors.scss";

.user-map-part {
    &-user-image {
        width: 175px;
        height: 175px;
        border-radius: 50%;
        border: solid 2px $primary;
        background-color: $white;
        max-height: inherit;
        max-width: inherit;
    }


    &-modal-image {
        width: 250px;
        height: 250px;
        border-radius: 50%;
        border: solid 2px $primary;
        background-color: $white;
    }

    &-user-part {
        border-bottom: solid 1px #ccc;
    }

    &-user-column {
        max-width: 12%;
        border-right: solid 1px #ccc;
        padding-top: 1rem;
    }

    &-user-heading {
        color: $primary;
    }

    &-role-heading {
        color: $primary;
        text-align: center;
        width: 100%;
    }

    &-role-image {
        width: 125px;
        height: 125px;
        border-radius: 50%;
        border: solid 2px $primary;
        background-color: $primary;
    }
}





