@import "../../../../styles/_variables.scss";
@import "../../../../styles/_breakpoints.scss";

.header-nav {
    .nav-item {
        .nav-link {
            border-bottom: 2px solid transparent;
            color: $navbar-color;
            min-height: 2rem;
            display: flex;
            align-items: center;
            margin-right: 2px;

/*            // Don't want any icons on the header.
            .nav-icon {
                display: none;
            }*/

            &.active {
                font-weight: 600;
                background-color: $navbar-active-color;
                border-radius: 0.5rem;
                color: white;

                &:hover {
                    background-color: lighten($navbar-active-color, 5%);
                }
            }

            &:hover {
                border-bottom-color: $navbar-color;
                border-bottom-width: 2px;
                border-bottom-style: solid;
            }
        }
        // Titles.
        &.nav-title {
            display: none;
        }
    }
}


.nav-item {
    a.nav-link {

    }
}