@import "../../../styles/_colors.scss";

.search-input {
    .search-input-input {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        padding-left: 20px;
    }

    .search-input-button {
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        padding-right: 15px;

        &:not(:hover) {
            background-color: $white;
            color: $secondary;
            border-left-style: none;
            border-color: $gray-200;
        }
    }
}
