.cards-or-table {
    &.cards-or-table-cards {
        padding-top: 0.5rem;

        .cards-or-table-card {
            min-height: 95%;
            margin-bottom: 0.5rem;

            .card-footer {
                background-color: transparent;
            }
        }
    }

    &.cards-or-table-table {
        .cards-or-table-table-buttons-td {
            text-align: right;
        }
    }
}
