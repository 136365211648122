﻿// Colors
//
// These colours can be included by any scss files for components if required.
// They also get included in in the main imports for bootstrap and coreui by the layout to override their colours.

// Brand colours
$brand-black: #080a30;
$brand-blue: #3d4ffa;
$brand-green: #14d6c2;
$brand-light-gray: #edf2f7;
    
$brand-purple: #9D5097;
$brand-orange: #FD5000;
$brand-pink: #E5007E;
//$brand-blue: #1A1C35;

// Standard bootstrap colour pallet names the defaults are taken from bootstrap.
// When changing one for this project, remove the !default to people looking at the code in the future know its a custom colour.
$blue: #0d6efd !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #d63384 !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #198754 !default;
$teal: #20c997 !default;
$cyan: #0dcaf0 !default;

// Useful monochrome colours.   The defaults are again taken from bootstrap.
// When changing one for this project, remove the !default to people looking at the code in the future know its a custom colour.
$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

// Main colours for the app's theme.  The defaults are again taken from bootstrap.
// You will often change some of these based on the brand colours for the brand above.
// When changing one for this project, remove the !default to people looking at the code in the future know its a custom colour.
$primary: $brand-black !default;
$secondary: $brand-blue !default;
$tertiary: $brand-green !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-700 !default;


// Explict light and dark pairs.
$dark-bg: $dark;
$dark-color: $white;
$light-bg: $white;
$light-color: $gray-800;

// Colours pairs for main areas.
$body-bg: $white;
$body-color: $gray-900;
$main-bg: transparent;
$main-color: $gray-900;
