.consume-file {
    &-title {
        text-align: center;
    }

    &-video {
        display: flex;
        margin: 0 auto;
    }

    &-video[poster] {
        width: 60%;
    }
}
