@import "../../styles/_breakpoints.scss";
@import "../../styles/_colors.scss";

.video-tile {
    display: inline-block;
    width: 200px;
    height: 200px;
    margin-right: 10px;
    border-radius: 0; // Remove the border radius, we always want these to be square.
    background-color: $white;

    &-body {
        background: $primary;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        color: $white;
    }

    &-badge {
        position: absolute;
        top: 8px;
        right: 8px;
    }

    &-options {
        position: absolute;
        bottom: 70px;
        right: 8px;
        z-index:10;
    }

    &-browse-video {
        overflow: hidden;
        margin-right: 8px;
        margin-bottom: 8px;
        display: inline-block;
    }

    &-fixed {
        width: 100%;
        height: auto;
    }

    &-auto {
        width: 100%;
        height: auto;
    }

    &-video-thumbnail {
        //border: 1px solid lightgray;
        border-radius: 2px 2px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position-x: center;
        background-position-y: center;
        text-align: center;

        &-overlay {
            font-size: 3rem;
            text-align: center;
            opacity: 0.8;
            cursor: pointer;
            color: $primary;
        }
    }

    &-browse-video-name {
        font-weight: bold;
        font-size: small;
        max-width: 350px;
        height: 2.4rem;
        cursor: pointer;
        white-space: break-spaces;
        text-overflow: ellipsis;
    }
}

@include media-breakpoint-up(md) {
    // Fixed sized video tiles.
    $video-width: 350px;

    .video-tile {
        &-fixed {
            width: $video-width;
            //height: calc((($video-width / 16) * 9) + 110px);

            .card-img {
                height: calc(($video-width / 16) * 9);
                object-fit: contain; // This is the key to keeping the aspect ratio.
            }

            .card-img-overlay {
                padding-top: calc(((($video-width / 16) * 9) / 2) - 30px);
            }
        }

        &-browse-video-name {
            overflow-y: hidden;
        }
    }
}
