@import "../../../styles/_breakpoints.scss";
@import "../../../styles/_colors.scss";

.main-container {
    padding-top: 12px;
    padding-bottom: 12px;
    border-radius: 2rem;

    &.main-container-main {
        background-color: $main-bg;
        color: $main-color;
    }

    &.main-container-transparent {
        background-color: transparent;
    }
    // If we are not fluid then give some extra padding to visually work with the border radius.
    &:not(.container-fluid) {
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    @include media-breakpoint-up(sm) {
        //margin-top: 10px;
        /*border-radius: 10px 10px;*/
    }

    @include media-breakpoint-down(xs) {
        // Override the default padding added by .container-fluid on xs screens.
        &.container-fluid {
            padding-left: 5px;
            padding-right: 5px;
        }
    }

    // Centering of children.
    //
    &.main-container-center-children-none {
        /* Do nothing */
    }

    &.main-container-center-children-vertically {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &.main-container-center-children-horizontally {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
}

