@import "../../../styles/_colors.scss";

.uploaded-video-preview {
    margin-bottom: 0.2rem;
    border-color: $gray-300;
    border-style: solid;
    border-width: 1px;
    padding: 4px 4px 4px 4px;
    text-align: center;

    .uploaded-video-preview-no-video {
        color: $gray-700;
    }
}
