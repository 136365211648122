@import "../../../styles/_colors.scss";
@import "../../../styles/_variables.scss";

.user-nav-register-button {
    margin-right: 20px;
}

.user-nav-circle {
    height: 36px;
    width: 36px;
    background-color: $secondary;
    text-align: center;
    display: flex;
    flex-direction: column;
    padding-top: 4px;
    margin-top: -4px;

    border-radius: 36px;

    color: $white;
}