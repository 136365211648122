@import "../../styles/_colors.scss";

.html-display {
    // Format tables to they appear the same between HtmlDisplay and HtmlEditor.
    table {
        width: 100%;

        td {
            border: 1px solid $gray-300;
        }
    }
    // Embedded youtube videos should be full width and 16x9 sized.
    oembed {
        display: block;
        overflow: hidden;
        /* 16:9 aspect ratio */
        width: 100%;
        padding-top: 56.25%;
        position: relative;

        iframe {
            border: 0;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }
    }
    img {
        max-width: 100%;
    }

    // In textOnly mode hide everything that is not text and shirnk the headings.
    &.html-display-text-only {
        table { display: none; }
        figure { display: none; }
        img { display: none; }
        oembed { display: none; }
        iframe { display: none; }
        video { display: none; }

        h1, h2, h3, h4, h5, h6, h7, h8, h9 { font-size: 1rem; }
    }
}

// Special handling to not take the table-striped formatting when we are previewing html within a list.
table.table-striped {
    .html-display {
        table:not(.table-striped) {
            tbody {
                tr:nth-of-type(odd) {
                    background-color: transparent;
                }
            }
        }
    }
}
