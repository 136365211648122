.form-buttons {
    text-align: right;
    // Give some space between buttons.
    .btn {
        margin-right: 2px;
    }

    .btn-group {
        margin-right: 2px;

        .btn {
            margin-right: 0px;
        }
    }

    &.form-buttons-top {
        &:not(.form-buttons-no-padding) {
            margin-top: 0.6em;
            margin-bottom: 0em;
            padding-top: 0px;
        }
    }

    &.form-buttons-bottom {
        /*border-top: 1px solid lightgray;*/
        &:not(.form-buttons-no-padding) {
            margin-top: 2em;
            margin-bottom: 2em;
            padding-top: 4px;
        }
    }
}
