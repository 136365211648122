@import "../../../../styles/_colors.scss";
@import "../../../../styles/_variables.scss";

.sidebar-minimizer {
    background-color: transparent;
    border-bottom-right-radius: $sidebar-inner-corner-radius;
    //padding-right: $sidebar-inner-corner-radius;
    padding-right: 30px;
    height: max(30px, $sidebar-inner-corner-radius);
    width: 100%;
    text-align: right;
    cursor: pointer;

    &:hover {
        background-color: $primary;
    }
}
