@import "../../styles/_colors.scss";

.role-map-part {
    &-role-image {
        width: 175px;
        height: 175px;
        border-radius: 50%;
        border: solid 2px $primary;
        background-color: $primary;
        max-height: inherit;
        max-width: inherit;
    }

    &-user-image {
        width: 125px;
        height: 125px;
        border-radius: 50%;
        border: solid 2px $primary;
        background-color: $white;
    }

    &-modal-image {
        width: 250px;
        height: 250px;
        border-radius: 50%;
        border: solid 4px $primary;
        background-color: $primary;
    }

    &-role-part {
        border-bottom: solid 1px #ccc;
    }

    &-role-column {
        max-width: 12%;
        border-right: solid 1px #ccc;
        padding-top: 1rem;
    }

    &-role-heading {
        color: $primary;
    }

    &user-heading {
        color: $primary;
        text-align: center;
        width: 100%;
    }
}
.role-image {
    width: 175px;
    height: 175px;
    border-radius: 50%;
    border: solid 2px $primary;
    background-color: $primary;
    max-height: inherit;
    max-width: inherit;
    align-self: center;
}

.user-image {
    width: 125px;
    height: 125px;
    border-radius: 50%;
    border: solid 2px $primary;
    background-color: $white;
}

.modal-image {
    width: 250px;
    height: 250px;
    border-radius: 50%;
    border: solid 4px $primary;
    background-color: $white;
}

.role-part {
    border-bottom: solid 1px #ccc;
}

.role-column {
    max-width: 12%;
    border-right: solid 1px #ccc;
    padding-top: 1rem;

}

.role-heading {
    color: $primary;

}

.user-heading {
    color: $primary;
    text-align: center;
    width: 100%;
}
