@import "../../../styles/_breakpoints.scss";
@import "../../../styles/_colors.scss";

$background-image-default: linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.7)), url(./default/overview-background.png);

// Put the default background on the body (to avoid flicker switching around and allow it to be changed by supplying different data-background="" values.
body {
    background-image: $background-image-default;
    background-color: $white;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    //color: $dark-color;
    min-height: 100vh;
    background-attachment: fixed;
}

body[data-background="default"] {
    background-image: $background-image-default;
    background-size: cover;
}

