@import "../../../../styles/_variables.scss";

.app-navbar-brand {
    min-width: 200px;
    min-height: $navbar-height - 10px;
    height: 100%;
    background-image: url(../../../../styles/images/brand/Talent-EdMatrix_Logo_OnDark.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    background-clip: padding-box;
    cursor: pointer;
}
