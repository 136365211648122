@import "../../styles/_colors.scss";
@import "../../styles/_breakpoints.scss";

$segmentHeight: 150px; // This is the height of the straight segment of the road SVG.
$straightSegmentWidth: 50px; // This is the width of the straight segment of the road SVG.
$curvedSegmentWidth: 92px; // This is the width of the curved segment of the road SVG.
$badgeSize: 120px; // This is the size of the badge that sits on the road.
$badgeSizeMobile: 80px; // This is the size of the badge that sits on the road on mobile.

.career-journey {
    width: 1000px;
    margin: 0 auto;

    &-item {
        display: flex;
        justify-content: center;
        margin-bottom: -1px; // Used to remove the whitespace between rows. (Where the turns connect)

        &-straight {
            background: url("../../../public/img/RoadStraightSmaller.svg") no-repeat no-repeat;
            width: $straightSegmentWidth;
            height: $segmentHeight;
            margin: 0 -1px; // Used to remove the whitespace between segments
        }

        &-down-left {
            background: url("../../../public/img/RoadDownLeftSmaller.svg") no-repeat no-repeat;
            width: $curvedSegmentWidth;
            height: $segmentHeight;
        }

        &-down-right {
            background: url("../../../public/img/RoadDownRightSmaller.svg") no-repeat no-repeat;
            width: $curvedSegmentWidth;
            height: $segmentHeight;
        }

        &-up-left {
            background: url("../../../public/img/RoadUpLeftSmaller.svg") no-repeat no-repeat;
            width: $curvedSegmentWidth;
            height: $segmentHeight;
        }

        &-up-right {
            background: url("../../../public/img/RoadUpRightSmaller.svg") no-repeat no-repeat;
            width: $curvedSegmentWidth;
            height: $segmentHeight;
        }

        &-blank-corner {
            width: $curvedSegmentWidth;
            height: $segmentHeight;
        }

        &-blank-straight {
            width: $straightSegmentWidth;
            height: $segmentHeight;
            margin: 0 -1px; // This is required to keep the alignment
        }

        &-role {
            // Used so we can hide any imperfections as the straight segement joins a curve.
            position: relative;
            // Align the text center of the circle.
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            height: $badgeSize;
            width: $badgeSize;
            padding: 4px;
            background: rgba($primary, 0.8);
            border: solid 4px $white;
            border-radius: 50%;
            font-size: 1rem;
            color: $white;
            cursor: pointer;
            // Used to create some depth of the circles on the road.
            box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
            // Transition for scale on hover
            transition: transform ease-in 0.3s;

            font-size: 0.8rem;

            &:hover {
                transform: scale(1.2);
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .career-journey {
        max-width: 100vw;

        &-item {
            &-role {
                width: $badgeSizeMobile;
                height: $badgeSizeMobile;
                font-size: 0.75rem;
            }
        }
    }
}
