@import "../../../styles/_colors.scss";

.organisation-profile-timeline {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &-item {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
    }

    &-circle {
        background-color: $primary;
        color: #fff;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 20px;
        z-index: 1;
    }

    &-content {
        padding: 10px;

        & h3 {
            margin: 0;
        }
    }

    // This is the line. We are using a pseudo element to create it. The selector :not(:last-child) is used to prevent the line from being added to the last item.
    &-item:not(:last-child)::before {
        content: "";
        background-color: $primary;
        width: 2px;
        height: 40px;
        position: absolute;
        margin-left: 20px;
        margin-top: 80px;
    }
}
