﻿// Global styling, not specific to any component.
//
// NOTE this file should be very small.  Do not add anything here without a second opinion!
//

@import "_colors.scss";

// Use the body colours.
body {
    background-color: $body-bg;
    color: $body-color;
}

// Dropdown toggles should not be rounded on the left in btn-groups.
.btn-group {
    > .dropdown {
        > .dropdown-toggle {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-left-style: none;
        }
    }
}