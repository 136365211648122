@import "../../../../styles/_variables.scss";

$icon-margin: 6px;

.sidebar-nav {
    .nav-item {
        display: block;
        width: 100%;
        min-height: 3rem;
        display: flex;

        .nav-link {
            display: block;
            width: 100%;
            height: 100%;
            color: $sidebar-color;
            padding-top: 0.7rem;

            .nav-icon {
                color: $sidebar-icon-color;
                margin-right: $icon-margin;
            }

            &.active {
                background-color: $sidebar-highlight-bg;
                color: $sidebar-highlight-color;

                .nav-icon {
                    color: $sidebar-highlight-icon-color;
                    margin-right: 6px;
                }
                // Rounder the border of the active item.
                border-radius: 0.5rem;

                &:hover {
                    border-radius: 0px;
                }
            }

            &:hover {
                background-color: $sidebar-highlight-bg;
                color: $sidebar-highlight-color;

                .nav-icon {
                    color: $sidebar-highlight-icon-color;
                    margin-right: 6px;
                }
            }
        }
        // Titles.
        &.nav-title {
            padding-left: 14px;
            font-size: 80%;
            text-transform: uppercase;
            min-height: auto;
            margin-top: 0.2rem;
            margin-bottom: 0.2rem;
            font-weight: 500;
        }
    }
}
